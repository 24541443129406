<template>
  <div class="">
    <!--<video id="background-video" autoplay loop muted poster="https://public-resource-letztrav.s3.amazonaws.com/3423424h45gh23v45g34v35g.png">
        <source src="https://www.youtube.com/embed/q-81zG8Qp10?autoplay=1&mute=1&loop=1" type="video/mp4">
    </video>-->
    <!--<iframe id="background-video" src="https://www.youtube.com/embed/q-81zG8Qp10?autoplay=1&mute=1&loop=1">
    </iframe>-->
    <a v-if="!reproduciendo" @click="play"  class="play-btn" href="#"></a>
    <img v-if="!reproduciendo"  id="background-video" width="100vw" src="https://public-resource-letztrav.s3.amazonaws.com/backgroundwolrdletztrav.jpeg" alt="">
    <youtube v-if="reproduciendo"
    :video-id="videoId"
    :player-width="windowWidth"
    :player-height="windowHeight"
    @ready="ready"
    @playing="playing"
    @paused="pause"
    @ended="ended"
    :player-vars="{ autoplay: 1 }"
  ></youtube>
  </div>
</template>

<script>
  import { getIdFromURL } from "vue-youtube-embed";
  let videoId = getIdFromURL("https://www.youtube.com/embed/q-81zG8Qp10");
  export default {
    name:'BackgroundVideo',
    data (){
      return {
        videoId,
        tareas:[],
        tarea:null,
        reproduciendo:false,
        windowHeight:window.innerHeight,
        windowWidth:window.innerWidth,
      }
    },
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
    },

    beforeDestroy() { 
      window.removeEventListener('resize', this.onResize); 
    },
    methods:{
      onResize() {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth
      },
      ready(event) {
        console.log(event)
        this.player = event.target;
      },
      playing(event) {
        console.log("playing");
        console.log(event)
      },
      stop() {
        console.log("ended");
        this.player.stopVideo();
      },
      pause() {
        this.player.pauseVideo();
        console.log("paused");
        this.reproduciendo = false
      },
      ended() {
        console.log("ended");
        this.reproduciendo = false
      },
      play() {
        this.reproduciendo = true
        this.player.playVideo();
        console.log("playing de nuevoooo");
      },
      hacerlogin(){

      }
    },
    created(){
    },
    watch:{
    },
  }
</script>
<style>
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}


.video {
  height: 100%;
  width: 100%;
}

.video--cover {
  /* Soporte para navegadores antiguos */
  -moz-object-fit: cover;
  -webkit-object-fit: cover;
  
  /* Estándar CSS */
  object-fit: cover;
}
/* TODO: Si se requiere ingresar imagen para mostrar en dispositivos moviles solo descomentar las siguientes lineas */
/* @media (max-width: 750px) {
    #background-video { display: none; }
    body {
      background: url("https://public-resource-letztrav.s3.amazonaws.com/3423424h45gh23v45g34v35g.png") no-repeat;
      background-size: cover;
    }
} */

.play-btn {
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  background: radial-gradient( rgba(0, 47, 165, 0.8) 60%, rgba(255, 255, 255, 1) 62%);
  border-radius: 50%;
  position: absolute;
  display: block;
  box-shadow: 0px 0px 25px 3px rgba(0, 47, 165, 0.8);
}

/* triangle */
.play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play-btn:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, .75);
  top: -25%;
  left: -25%;
  background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;

  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;

  }
}
</style>